<template>
  <div>
    <div class="page-header">
      <h1>
        <span>{{ $t("reports") }} &ndash;</span>
        {{ $t("projects") }}
      </h1>
    </div>

    <b-loading v-model="loading"></b-loading>

    <FilterAndSettings
      v-if="resultsFilter"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></FilterAndSettings>

    <ResultsTable
      v-if="resultsFilter"
      :loading="loading"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></ResultsTable>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Currencies from "../../helpers/Currencies";
import ProjectsConfig from "./config/ProjectsConfig";
import FilterAndSettings from "./partials/FilterAndSettings";
import ResultsFilter from "./partials/ResultsFilter";
import ResultsTable from "./partials/ResultsTable";

export default {
  name: "ReportProjects",
  components: {
    FilterAndSettings,
    ResultsTable,
  },
  data() {
    return {
      loading: true,
      config: new ProjectsConfig(),
      resultsFilter: null,
      settings: {
        currency: "EUR",
        conversionRate: Currencies.euroToDollarConversionRate,
      },
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.global.currencies,
    }),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllMetadata"]),
    async load() {
      const xViewMetadata = await this.getAllMetadata({
        bestScalingRun: true,
      });

      for (const i of xViewMetadata) {
        i.date = i.projectDate;
        i.device = i.serialNumber;
        i.accessory = i.wheelUnitUsed;
        i.currency = this.currencies[0]; // TODO: Get currency from API?
      }

      this.resultsFilter = new ResultsFilter(xViewMetadata);

      this.loading = false;
    },
  },
};
</script>
