import i18n from "../../../plugins/i18n";
import store from "../../../store";
import BaseConfig from "./BaseConfig";

export default class ProjectsConfig extends BaseConfig {
  filterDate = true;

  convertable = !store.getters.userHasRoleSuperAdmin;

  columns = [
    {
      label: i18n.t("project_date"),
      field: "projectDate",
      component: "DateColumn",
    },
    {
      label: i18n.t("uploader"),
      field: "user.name",
      component: "StringColumn",
    },
    {
      label: i18n.t("field_operators"),
      field: "fieldOperator",
      component: "StringColumn",
    },
    {
      label: i18n.tc("device"),
      field: "device",
      component: "StringColumn",
    },
    {
      label: i18n.tc("accessory"),
      field: "accessory",
      component: "StringColumn",
    },
    {
      label: i18n.t("approximate_length"),
      field: "approximateLengthCalculated",
      component: "DistanceColumn",
      numeric: true,
    },
    {
      label: i18n.t("pipe_type"),
      field: "pipeType",
      component: "StringColumn",
    },
    {
      label: i18n.t("pipeline_owner"),
      field: "pipeOwner",
      component: "StringColumn",
    },
    {
      label: i18n.t("royalties"),
      field: "royalties",
      component: "MoneyColumn",
      numeric: true,
    },
    {
      label: i18n.t("royalties_converted"),
      field: "royalties",
      component: "MoneyConvertedColumn",
      numeric: true,
      visible: this.convertable,
    },
  ];
}
